<template>
  <AssetTypeVideo />
  <div class="container h-100 d-flex align-items-center justify-content-center z-2">
    <div class="row mobile align-content-between g-0">
      <div class="col-12 ps-3 pe-3 d-flex align-items-center justify-content-end theme-header">
        <router-link :to="{ name: 'DashboardHome' }" class="btn-back"></router-link>
        <p class="m-auto">{{ this.displayHeading }}</p>
        <button class="btn" type="button">
          <a target="_blank" href="https://staging.metaluxe.com.au/monarch/login" class="d-none">
            <img src="/img/ui-theme/menu-icon-black.svg">
          </a>
        </button>
      </div>

      <div class="col-12 d-flex align-items-center justify-content-center z-2">
        <div class="row g-0">
          <div class="col-12">

            <template v-for="assetType in assetTypes" :key="assetType.Guid">

              <!-- Video links -->
              <router-link :to="['Penthouse 11502', 'River 1 Exterior Building', 'River 1 Rooftop Amenities'].includes(assetType.ObjectName) ?
                {
                  name: componentName, params:
                  {
                    assetType1: this.assetType,
                    assetType: assetType.LinkName
                  }
                } : '#'" class="btn-theme-menu mb-2">
                {{ assetType.ObjectName }}
              </router-link>

            </template>

          </div>
        </div>
      </div>

      <TypeBotNav />
    </div>
  </div>
  <OffCanvas />
</template>

<script>
import AssetTypeVideo from '@/components/AssetTypeVideo.vue'
import TypeTopNav from '@/components/TypeTopNav.vue'
import TypeBotNav from '@/components/TypeBotNav.vue'
import OffCanvas from "@/components/OffCanvas.vue";
import global from '../global'
import BunnyNetService from "@/services/BunnyNetService.js";
import { unslugify } from "unslugify";
import sortArray from 'sort-array'

export default {
  name: "AssetType1",
  props: {
    assetType: String
  },
  components: {
    AssetTypeVideo,
    TypeTopNav,
    TypeBotNav,
    OffCanvas
  },
  data() {
    return {
      assetTypes: null,
      collection: null,
      componentName: "AssetType",
      folderStructure: encodeURIComponent(this.theme.devFolder + '/assets/' + this.assetType + '/'),
      assetOrder: null
    }
  },
  created() {
    BunnyNetService.getFolders(this.folderStructure)
      .then(response => {
        // Remap array
        const menuItems = response.data.map((item) => {
          return {
            Guid: item.Guid,
            ObjectName: unslugify(item.ObjectName),
            LinkName: item.ObjectName,
            ComponentName: this.componentName,
            Path: item.Path,
            IsDirectory: item.IsDirectory,
            StorageZoneName: item.StorageZoneName
          };
        });
        // Assign type menu array
        if (this.assetType === 'River-1') {
          this.assetOrder = this.theme.river1MenuOrder
        } //else if (this.assetType === '2-bed') {
        //this.assetOrder = this.theme.bed2MenuOrder
        //}

        // Sort array
        const customMenu = sortArray(menuItems, {
          by: ['LinkName'],
          order: 'LinkName',
          customOrders: {
            LinkName: this.assetOrder
          }
        })

        console.log(customMenu)

        this.assetTypes = customMenu
      })
      .catch(error => {
        console.log(error)
      })
  },
  setup() {
    // load global config
    const { theme } = global

    return {
      theme
    }
  }
}
</script>
