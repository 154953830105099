import { reactive } from "vue";

const theme = reactive({
    agentLogo: "Footer.png",
    devLogo: "Header.png",
    apiUrl: "https://api.metaluxe.com.au/",
    bunnyApiUrl: "https://api.bunny.net/",
    accessKey: "07aeb6d1-92b0-48fc-9fa3-e202bc32d8753a016979-9f0b-4b1d-8027-5575c13bad47",
    streamToken: "2d5c56ec-04a8-4fb0-94ec-6e8fd5b1d436",
    videoLibraryId: "157147",
    streamCdnUrl: "https://vz-7a0e9e8f-539.b-cdn.net",
    streamApiKey: "b4f6f669-48c2-45f6-90376d680f38-eb44-431f",
    storageApiPassword: "96e351c2-908e-4948-84ac3d668370-1dd5-4222",
    cdnUrl: "https://monarch-pwa.b-cdn.net",
    devFolder: "/monarch-pwa",
    googleAnalytics: "",
    videosIcon: "/img/ui-theme/videos-icon.svg",
    rendersIcon: "/img/ui-theme/renders-icon.svg",
    rangeIcon: "/img/ui-theme/range-icon.svg",
    panoramasIcon: "/img/ui-theme/panoramas-icon.svg",
    floorplansIcon: "/img/ui-theme/floorplans-icon.svg",
    dashboardMp4: "theme/dashboard/4K_dashboard.mp4",

    // top level menu
    assetsMenuOrder: [
        'River 1',
        'River 2 (Sold)'
    ],
    river1MenuOrder: [
        'Riverhome-10103-(Sold)',
        'Riverhome-10104-(Sold)',
        'Penthouse-11501-(Sold)',
        'Penthouse-11502',
        'Penthouse-11503-(Sold)',
        'Penthouse-11504-(Sold)',
        'River-1-Exterior-Building',
        'River-1-Rooftop-Amenities'
    ],
    penthouse11502MenuOrder: [
        'videos',
        'virtual-tour',
        'renders',
        'floorplans'
    ],
    river1ExteriorMenuOrder: [
        'videos',
        'renders',
    ],
    rooftopAmenitiesMenuOrder: [
        'videos',
        'virtual-tour',
        'renders',
    ],
    penthouse11502Asset: {
        videos: [],
        videosName: [],
        panoramas: [0],
        panoramasName: [
            'Monarch Penthouse 11502'
        ],
        panoramasUrl: [
            'https://storage.net-fs.com/hosting/7566552/0/'
        ],
        balcony: [0],
        balconyName: [],
        balconyUrl: [],
        renders: [],
        rendersName: [],
        rendersUrl: [],
        floorplans: [],
        floorplansName: [],
        floorplansUrl: [],
        range: [],
        rangeName: [],
        rangeUrl: [],
        pdf: [0],
        pdfName: [],
        pdfUrl: [],
    },

    river1ExterioAsset: {
        videos: [],
        videosName: [],
        panoramas: [0],
        panoramasName: [],
        panoramasUrl: [],
        balcony: [0],
        balconyName: [],
        balconyUrl: [],
        renders: [],
        rendersName: [],
        rendersUrl: [],
        floorplans: [],
        floorplansName: [],
        floorplansUrl: [],
        range: [],
        rangeName: [],
        rangeUrl: [],
        pdf: [0],
        pdfName: [],
        pdfUrl: [],
    },
    rooftopAmenitiesAsset: {
        videos: [],
        videosName: [],
        panoramas: [0],
        panoramasName: [
            'River 1 Rooftop Amenities'
        ],
        panoramasUrl: [
            'https://storage.net-fs.com/hosting/7566552/1/'
        ],
        balcony: [0],
        balconyName: [],
        balconyUrl: [],
        renders: [],
        rendersName: [],
        rendersUrl: [],
        floorplans: [],
        floorplansName: [],
        floorplansUrl: [],
        range: [],
        rangeName: [],
        rangeUrl: [],
        pdf: [0],
        pdfName: [],
        pdfUrl: [],
    },
});

export default { theme };
