<template>
  <div class="col-12 ps-3 pe-3 d-flex align-items-center justify-content-end theme-header">
    <router-link :to="{ name: 'DashboardHome' }" class="btn-back"></router-link>
    <p class="m-auto">{{ this.displayHeading }}</p>
    <button class="btn" type="button">
      <a target="_blank" href="https://staging.metaluxe.com.au/monarch/login" class="d-none">
        <img src="/img/ui-theme/menu-icon-black.svg">
      </a>
    </button>
  </div>
</template>

<script>
import global from '../global'
import { unslugify } from "unslugify";

export default {
  name: 'TypeTopNav',
  props: [
    'heading'
  ],
  setup() {
    const { theme } = global
    let displayHeading = ""

    return {
      theme, displayHeading
    }
  },
  created() {
    this.displayHeading = unslugify(this.heading)
  }
};
</script>
